
import Sidebar from "@/components/Nav/Sidebar/Sidebar.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";

export default {
	name: 'Support',
	components: {DefaultButton, Sidebar},
	props: {
		SupportTitle: {
			type: String,
			default: '🤓 Support'
		},
		eMailSupport: {
			type: String,
			default: '📨 E-Mail Support:'
		},
		eMailInfo: {
			type: String,
			default: 'If you had any Questions that aren’t answered in the FAQ get in Contact with us:\n' +
				'\n' +
				'Please note out of the reason of many Requests, it would take time until we answer your E-Mail. We are endeavours to answer as soon as possible.'
		},
		eMailBtn: {
			type: String,
			default: 'support@boostpack.app'
		},
		phoneSupport: {
			type: String,
			default: '📞️ Phone Support:'
		},
		phoneInfo: {
			type: String,
			default: 'If you are a an Organisation or an Premium User, you an contact us via. Phone.\n' +
				'\nAt the moment there is only an Austrian Phone-Number available. and Service Calls. will be answered within our open hours. Mo-Fr.: 08:00 am - 5:00 pm.'
		},
		phoneBtn: {
			type: String,
			default: '+43677 62401670'
		},
		chatSupport: {
			type: String,
			default: '💬 Chat Support:'
		},
		chatInfo: {
			type: String,
			default: 'At the Moment no Live Chat is available.\n' +
				'\n' +
				'You can try to Contact us via our Social-Media Sites and their ready Messenger Solutions.'
		},
		chatBtn: {
			type: String,
			default: ''
		},
	},
	setup() {
		return {};
	}
}
